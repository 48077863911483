<template>
  <header :class="[$device.isDesktop ? 'px-32 py-24' : 'px-16 py-12']">
    <div class="p-drawer-header place-items-between">
      <div
        :class="['place-items-start gap-8', headerLink && 'cursor-pointer']"
        @click="$emit('header-click')"
      >
        <p-icon
          v-if="props.back"
          icon="arrow-left"
          :class="['cursor-pointer', $device.isDesktop ? 'mr-12' : 'ml-6']"
          @click.stop="$emit('on-back')"
        />
        <slot name="title" />
        <div>
          <div class="place-items-start align-items-center gap-4">
            <h2
              v-if="props.title"
              class="f-title-20-bold text-left t-fg-primary"
              v-text="props.title"
            />

            <slot name="titleAction" />
          </div>

          <p
            v-if="props.description"
            class="f-utility-14 text-left t-fg-secondary"
            v-text="props.description"
          />
        </div>
      </div>
      <div class="place-items-end">
        <slot name="actions" />
        <p-icon
          v-if="props.close"
          icon="close"
          class="cursor-pointer place-items-center mx-4"
          color="fg-secondary"
          @click="$emit('on-close')"
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
defineEmits(['on-back', 'on-close', 'header-click'])

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  back: {
    type: Boolean,
    default: true,
  },
  close: {
    type: Boolean,
    default: true,
  },
  isPicker: {
    type: Boolean,
    default: false,
  },
  headerLink: {
    type: Boolean,
    default: false,
  },
})
</script>
